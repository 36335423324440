import {
  deserializeNullToDefault,
  deserializeTime,
} from 'api/deserializers/common.deserializers.ts'
import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'
import { ImageModel } from 'api/models/image.model.ts'
import { MetroStationModel } from 'api/models/metro-station.model.ts'
import { Dayjs } from 'dayjs'
import { PriceSegment } from 'enums/places.enums.ts'
import {
  deserialize,
  JsonArray,
  JsonNameReadonly,
  JsonStruct,
} from 'tserialize'

export class PlaceModel {
  @JsonNameReadonly()
  id!: number

  @JsonArray(ImageModel, 'additional_images')
  additionalImages: Array<ImageModel> | null = null

  @JsonNameReadonly()
  address: string = ''

  @JsonArray(MeetingCategoryModel)
  categories: Array<MeetingCategoryModel> = []

  @JsonNameReadonly('creator_user')
  creatorUser: { id: number } | null = null

  @JsonNameReadonly()
  description: string = ''

  @JsonStruct(ImageModel)
  image: ImageModel = new ImageModel()

  @JsonNameReadonly('is_favourite')
  isFavourite: boolean = false

  @JsonStruct(MetroStationModel, 'metro_station')
  metroStation!: MetroStationModel

  @JsonNameReadonly()
  name: string = ''

  @JsonNameReadonly('phone', deserializeNullToDefault('-'))
  phone: string = ''

  @JsonNameReadonly('price_segment')
  priceSegment!: PriceSegment

  @JsonNameReadonly()
  website: string = ''

  @JsonNameReadonly('work_start_time', deserializeTime)
  workStartTime: Dayjs | null = null

  @JsonNameReadonly('work_finish_time', deserializeTime)
  workFinishTime: Dayjs | null = null

  @JsonNameReadonly('yandex_oid')
  yandexOrgId: string = ''

  get prettyWorkingHours(): string {
    if (!this.workStartTime || !this.workFinishTime) {
      return '-'
    }
    const timeTemplate = 'HH:mm'
    return `${this.workStartTime.format(
      timeTemplate
    )} - ${this.workFinishTime.format(timeTemplate)}`
  }

  get isCreated(): boolean {
    return !!this.creatorUser
  }

  static fromServer(data: object): PlaceModel {
    return deserialize(data, PlaceModel)
  }
}
