import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { AxiosResponse } from 'axios'
import { NotificationStatus } from 'enums/notification.enums.ts'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import { getFormattedStrapiDate, getFormattedStrapiTime } from 'utils/date.tsx'
import { PlaceModel } from '../places/models/place.model.ts'
import { transport } from '../transport.ts'
import { GetUserMeetingsResponse } from './responses/get-user-meetings.response.ts'
import { TMeetingsFilters } from 'types/meetings-filters.ts'
import { GetUserMeetingsRequest } from './requests/get-user-meetings.request.ts'
import { FilterModel } from './models/filter.model.ts'
import { InvitationSortType } from 'enums/invitations-sort-types.enum.ts'

type TCreateUserMeetingRequestData = {
  selectedPlace: PlaceModel
  selectedMoodId: number
  selectedCategoryId: number
  userId: number
  timeslots: Array<TPickerTimeslot>
}

export class UserMeetingsTransport {
  getUserMeetings(filters: TMeetingsFilters): Promise<GetUserMeetingsResponse> {
    const request = new GetUserMeetingsRequest({
      filters: new FilterModel(filters),
    })
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/get-user-meetings`, {
        data: request.toServer(),
      })
      .then(({ data }: AxiosResponse<GetUserMeetingsResponse>) =>
        GetUserMeetingsResponse.fromServer(data)
      )
  }

  getUserInvitations(
    filters: TMeetingsFilters,
    sort: InvitationSortType
  ): Promise<GetUserMeetingsResponse> {
    const request = new GetUserMeetingsRequest({
      filters: new FilterModel(filters),
      sort,
    })
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/get-user-invitations`, {
        data: request.toServer(),
      })
      .then(({ data }: AxiosResponse<GetUserMeetingsResponse>) =>
        GetUserMeetingsResponse.fromServer(data)
      )
  }

  getArchiveMeetings(): Promise<GetUserMeetingsResponse> {
    return transport
      .post(`${import.meta.env.VITE_STRAPI_API}/api/get-archive-meetings`, {
        data: {},
      })
      .then(({ data }: AxiosResponse<GetUserMeetingsResponse>) =>
        GetUserMeetingsResponse.fromServer(data)
      )
  }

  getUserMeeting(id: string | number): Promise<UserMeetingModel> {
    return transport
      .get(`${import.meta.env.VITE_STRAPI_API}/api/user-meetings/${id}`)
      .then(({ data }: AxiosResponse) => UserMeetingModel.fromServer(data))
  }

  create(data: TCreateUserMeetingRequestData): Promise<object> {
    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/user-meetings`,
      {
        data: {
          user_meeting_mood: {
            connect: [data.selectedMoodId],
          },
          user_meeting_category: {
            connect: [data.selectedCategoryId],
          },
          meeting_place: {
            connect: [data.selectedPlace.id],
          },
          sanek_user: {
            connect: [data.userId],
          },
          meeting_timeslots: data.timeslots.map(
            (timeslot: TPickerTimeslot) => ({
              date: getFormattedStrapiDate(timeslot.date),
              time_from: getFormattedStrapiTime(timeslot.timeFrom),
              time_to: getFormattedStrapiTime(timeslot.timeTo),
            })
          ),
        },
      }
    )
  }

  cancelMeeting(id: number): Promise<void> {
    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/cancel-meeting`,
      {
        data: { id },
      }
    )
  }

  updateMeetingTimeslots(
    id: number,
    pickerTimeslots: Array<TPickerTimeslot>
  ): Promise<void> {
    return transport.put(
      `${import.meta.env.VITE_STRAPI_API}/api/user-meetings/${id}`,
      {
        data: {
          meeting_timeslots: pickerTimeslots.map(
            (timeslot: TPickerTimeslot) => ({
              date: getFormattedStrapiDate(timeslot.date),
              time_from: getFormattedStrapiTime(timeslot.timeFrom),
              time_to: getFormattedStrapiTime(timeslot.timeTo),
            })
          ),
        },
      }
    )
  }

  setMeetingConfirmation(id: number, isConfirmed: boolean): Promise<void> {
    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/meeting-confirm`,
      {
        data: {
          id,
          is_confirmed: isConfirmed,
        },
      }
    )
  }

  notifyMeetingStatus(id: number, status: NotificationStatus): Promise<void> {
    return transport.post(
      `${import.meta.env.VITE_STRAPI_API}/api/send-notification`,
      {
        data: { id, status },
      }
    )
  }
}

export const userMeetingsTransport = new UserMeetingsTransport()
