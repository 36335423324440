import { JSX, useEffect, useRef } from 'react'
import { TTelegramUser } from 'api/models/telegram.model.ts'
import { userStore } from 'store/user.store.ts'
import { AppService } from '../api/services/app.service.ts'

declare global {
  interface Window {
    onTelegramAuth?: (user: TTelegramUser) => void
  }
}

export const TelegramSignBtn = (): JSX.Element => {
  const telegramWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.onTelegramAuth = handleTelegramAuth

    return () => {
      window.onTelegramAuth = undefined
    }
  }, [])

  useEffect(() => {
    if (!telegramWrapperRef.current) {
      return
    }

    const scriptElement = document.createElement('script')
    scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22'
    scriptElement.setAttribute(
      'data-telegram-login',
      import.meta.env.VITE_SANEK_BOT
    )
    scriptElement.setAttribute('data-size', 'large')
    scriptElement.setAttribute('data-onauth', 'window.onTelegramAuth(user)')
    scriptElement.setAttribute('data-request-access', 'write')
    scriptElement.async = true

    telegramWrapperRef.current.appendChild(scriptElement)
  }, [])

  return <div ref={telegramWrapperRef} />
}

function handleTelegramAuth(user: TTelegramUser): void {
  userStore.getUser(user).then(() => AppService.initStoreData())
}
