import { css } from '@emotion/css'

const textCommon = css`
  color: #000;
  text-align: center;
  font-family: 'Copperplate', serif;
  box-sizing: border-box;
  z-index: 1;
`

export const mainContainer = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
`

export const header = css`
  ${textCommon};
  width: 244px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 7px;
`

export const starsBlock = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
  padding: 0;
  ${textCommon};
  font-family:
    Chalkboard SE,
    serif;
  width: 325px;
  font-size: 32px;
  font-weight: 400;
`

export const title = css`
  ${textCommon};
  font-family:
    Chalkboard SE,
    serif;
  width: 325px;
  font-size: 32px;
  font-weight: 400;
  margin-top: -10px;
`

export const sanekPng = css`
  z-index: 1;
`

export const spraySvg = css`
  position: absolute;
  z-index: 0;
  top: 100px;
  width: 100%;
`

export const buttonBlock = css`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0 35px 45px;
  z-index: 1;
`
export const buttonGetFeedback = css`
  position: absolute;
  z-index: 1;
  top: 455px;
  width: calc(100% - 60px);
  max-width: 323px;
`

export const buttonGetFeedbackStyles = css`
  font-size: 17px;
`

export const sanekButtonVortex = css`
  position: absolute;
  z-index: 1;
  top: -17px;
  right: 17px;
`
