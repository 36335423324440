import { css } from '@emotion/css'

const textCommon = css`
  color: #000;
  font-style: normal;
  line-height: normal;
`

const buttonCommon = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 10px);
  padding: 0px 60px 10px 0px;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  border: 5px solid #f2f2f2;
  border-left: none;
  box-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
`

const countContainer = css`
  position: absolute;
  right: 60px;
  display: flex;
  align-items: center;
  width: auto;
  height: 18px;
  justify-content: center;
  font-family: Copperplate, serif;
  font-size: 10px;
  font-weight: 300;
  ${textCommon};
`

const count = css`
  display: flex;
  width: 15px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-family: Copperplate, serif;
  font-size: 14px;
  font-weight: 700;
  ${textCommon};
`

export const mainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0;
  gap: 40px;
  width: 100%;
  position: relative;
`

export const addMeetUpButton = css`
  ${buttonCommon};
  background: #ffde00;
`

export const showInvitesButton = css`
  ${buttonCommon};
  background: #9fcbdc;
`

export const leftPng = css`
  height: 80px;
`

export const buttonVortexImg = css`
  position: absolute;
  top: 130px;
  right: 15px;
`

export const rightSide = css`
  display: flex;
  align-items: center;
  gap: 14px;
  padding-top: 10px;
`

export const btnText = css`
  display: flex;
  width: 80px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-family: 'Chalkboard SE', serif;
  font-size: 18px;
  font-weight: 700;
  ${textCommon};
`

export const afterSanek = css`
  position: absolute;
  top: 271px;
  left: 0;
`

export const sanekPng = css`
  position: absolute;
  left: 10px;
`

export const thinkSanek = css`
  position: absolute;
  top: 0px;
  left: 105px;
`

export const meetingsCountContainer = css`
  ${countContainer};
  top: 124px;
`

export const invitationsCountContainer = css`
  ${countContainer};
  top: 264px;
  right: 85px;
`

export const meetingsCount = css`
  ${count};
  color: #68b984;
`

export const invitationsCount = css`
  ${count};
  color: #e41c2a;
`
