import * as styles from './phone.page.style.ts'

import dayjs from 'dayjs'
import { JSX, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { userStore } from 'store/user.store.ts'

import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'

import { SanekButton } from 'ui/sanek-button'
import { PhoneCodeInput } from 'ui/phone-code-input'
import { PhoneNumberInput } from 'ui/phone-number-input'

import { smsTransport } from 'api/sms.transport.ts'
import { AppService } from 'api/services/app.service.ts'

import { getSurveyRouteByUser } from 'utils/navigation.ts'

import { CenteredLoader } from 'components/common/centered-loader'

const validPhoneMaskLength = 18
const validSMSCodeLength = 4

export const RegisterPhonePage = observer((): JSX.Element => {
  const { user, isLoading: isLoadingUser } = userStore

  const [error, setError] = useState(false)
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showCodeInput, setIsShowCodeInput] = useState(false)
  const [deviceId, setDeviceId] = useState<string | null>(null)

  const isValidPhoneNumber = phone.length === validPhoneMaskLength
  const isValidSMSCodeLength = code.length === validSMSCodeLength

  useEffect(() => {
    if (!seconds) return

    const intervalId = setInterval(() => {
      const nextValue = seconds - 1
      setSeconds(nextValue)
      if (nextValue === 0) {
        clearInterval(intervalId)
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [seconds])

  useEffect(() => {
    setError(false)
  }, [code, phone])

  const sendSMSCode = async () => {
    setIsLoading(true)

    try {
      const preparedPhone = phone.replace(/[^0-9\\.]+/g, '')
      const response = await smsTransport.sendSMSCode(preparedPhone)
      const date = new Date()

      const codeValidInSeconds = dayjs(response.validTo).diff(
        dayjs(date),
        'seconds'
      )
      setSeconds(codeValidInSeconds)
      setDeviceId(response.uuid)
      setIsShowCodeInput(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePhoneChange = (newPhone: string) => {
    setCode('')
    setIsShowCodeInput(false)
    setPhone(newPhone)
  }

  const changePhoneNumber = () => {
    setPhone('')
    setIsShowCodeInput(false)
  }

  const auth = async () => {
    if (!deviceId || !code) return

    try {
      setError(false)
      setIsLoading(true)
      await userStore.smsCodeVerify({ code, uuid: deviceId })
      AppService.initStoreData()
      await userStore.getMe()
    } catch (e) {
      setError(true)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!user) {
      return
    }

    const route = user.hasPassedOnboarding
      ? getSurveyRouteByUser(user)
      : SanekRoute.INTRO_LETS_TALK
    void router.navigate(route)
  }, [user])

  if (isLoadingUser) {
    return <CenteredLoader />
  }

  return (
    <div className={styles.pageContainer}>
      <h1>Санёк зовёт</h1>
      <div className={styles.description}>Введи номер телефона</div>
      <div className={styles.inputWrapper}>
        <PhoneNumberInput value={phone} onChange={handlePhoneChange} />
      </div>
      {showCodeInput && (
        <div className={styles.action} onClick={changePhoneNumber}>
          Изменить номер телефона
        </div>
      )}
      {!showCodeInput && (
        <div className={styles.sendPhoneButton}>
          <SanekButton
            disabled={!isValidPhoneNumber}
            onClick={sendSMSCode}
            isLoading={isLoading}
          >
            Получить код
          </SanekButton>
        </div>
      )}
      {showCodeInput && (
        <>
          <div className={styles.description}>
            <div>Введи проверочный код из СМС</div>
          </div>
          <div className={styles.smsCodeForm}>
            <PhoneCodeInput value={code} onChange={setCode} />
          </div>
          {seconds && seconds > 0 ? (
            <div className={styles.hint}>
              Запросить повторно код можно через <br /> {seconds} сек
            </div>
          ) : (
            <div className={styles.action} onClick={sendSMSCode}>
              Выслать код повторно
            </div>
          )}

          <div className={styles.loginButton}>
            <SanekButton
              disabled={!isValidSMSCodeLength}
              isLoading={isLoading}
              onClick={auth}
            >
              Войти
            </SanekButton>
          </div>
          {error && (
            <div className={styles.errorText}>
              Пожалуйста, введите правильный код
            </div>
          )}
        </>
      )}
    </div>
  )
})
