import { LoadingScreen } from 'components/common/loading-screen/loading-screen.tsx'
import { JSX, useEffect, useState } from 'react'
import * as rdd from 'react-device-detect'
import { Navigate } from 'react-router-dom'
import { SanekRoute } from 'router/router.enums.ts'
import { observer } from 'mobx-react-lite'
import { userStore } from 'store/user.store.ts'
import { getSurveyRouteByUser } from 'utils/navigation.ts'

export const Root = observer((): JSX.Element => {
  const { isLoading, user } = userStore
  const [isUiLoading, setIsUiLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!rdd.isMobile) {
      return
    }

    const initialLoadingTimeoutId = setTimeout(
      () => setIsUiLoading(false),
      3000
    )

    return () => clearTimeout(initialLoadingTimeoutId)
  }, [])

  if (isLoading || isUiLoading) {
    return <LoadingScreen />
  }

  if (!user) {
    return <Navigate to={SanekRoute.INTRO_REGISTER} replace />
  }

  if (user.hasPassedSurvey) {
    return <Navigate to={SanekRoute.MAIN} replace />
  }

  if (user.hasPassedOnboarding) {
    return <Navigate to={getSurveyRouteByUser(user)} replace />
  }

  return <Navigate to={SanekRoute.INTRO_LETS_TALK} replace />
})
