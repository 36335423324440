import * as styles from './phone-code-input.style.ts'

import { ChangeEvent, FC } from 'react'
import { useMask } from '@react-input/mask'

type Props = {
  value?: string
  onChange?: (phone: string) => void
}
export const PhoneCodeInput: FC<Props> = ({ value, onChange }) => {
  const inputRef = useMask({
    mask: '____',
    replacement: { _: /\d/ },
  })

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value)
  }

  return (
    <div>
      <input
        ref={inputRef}
        type="tel"
        placeholder="xxxx"
        value={value}
        onChange={handleCodeChange}
        className={styles.phoneCoderInput}
      />
    </div>
  )
}
