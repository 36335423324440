import React from 'react'
import * as styles from './confirm-repeat-survey-modal.style'

import { LayoutPage } from 'components/layout-page'

import ManTall from 'assets/man_tall.png'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { SanekButton } from 'ui/sanek-button'
import { CellEmpty } from 'ui/cell-empty'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

type TConfirmRepeatSurveyModalProps = {
  onClose: VoidFunction
  onAccept: VoidFunction
}

export const ConfirmRepeatSurveyModal: React.FC<
  TConfirmRepeatSurveyModalProps
> = ({ onClose, onAccept }) => {
  useScrollToTop()
  return (
    <LayoutPage headerText="Пройти опрос" backAction={onClose}>
      <div className={styles.mainContainer}>
        <div className={styles.title}>Ты точно хочешь пройти опрос заново?</div>

        <CellEmpty height="35" />

        <div className={styles.imagesContainer}>
          <img
            src={ManTall}
            alt="Санёк"
            width="187"
            height="273"
            className={styles.ManTall}
          />
          <SeparatorGrayThin className={styles.delimeter} />
        </div>

        <CellEmpty height="8" />

        <div className={styles.description}>
          Если ты пройдешь опрос заново, то все твои старые ответы пропадут
        </div>

        <div className={styles.buttonContainer}>
          <SanekButton onClick={onAccept}>ПРОЙТИ ОПРОС</SanekButton>
        </div>
      </div>
    </LayoutPage>
  )
}
