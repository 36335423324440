import cn from 'classnames'

import { FC, useEffect, useState } from 'react'
import { ImageModel } from 'api/models/image.model.ts'

type Props = {
  image: ImageModel
  alt: string
  width?: string
  height?: string
  className?: string
}

export const ProgressiveImage: FC<Props> = ({
  image,
  alt,
  width,
  height,
  className,
}) => {
  const originalURL = image.url
  const formats = image.formats
  const thumbnailURL = formats ? image.formats.thumbnail.url : originalURL

  const [imageUrl, setImageUrl] = useState<string>(thumbnailURL)

  const loadOriginal = () => {
    const originalImage = document.createElement('img')
    originalImage.src = originalURL
    originalImage.onload = () => {
      setImageUrl(originalURL)
    }
  }

  useEffect(() => {
    loadOriginal()
  }, [])

  return (
    <>
      <img
        src={imageUrl}
        className={cn(className)}
        alt={alt}
        width={width}
        height={height}
      />
    </>
  )
}
