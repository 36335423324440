import { createBrowserRouter } from 'react-router-dom'

import { Root } from 'components/app/root.tsx'
import { AcceptInvitationSuccess } from 'pages/accept-invitation-success'
import {
  IntroLetsTalkPage,
  IntroRegisterPage,
  IntroSanekGreetingPage,
} from 'pages/intro'
import { ChatMessagesPage, CreateMessagePage } from 'pages/meeting-chat'
import { MeetingDetails } from 'pages/meeting-details'
import {
  SubscriptionBuyError,
  SubscriptionBuySuccess,
  Subscriptions,
  SubscriptionProceed,
} from 'pages/subscriptions'
import {
  SurveyCommon,
  SurveyQuestions,
  SurveyRoles,
  SurveyAvatars,
} from 'pages/survey'
import { SanekRoute } from './router.enums.ts'
import { SurveyCustomizationPage } from 'pages/survey/survey-customization'
import { MainPage } from 'pages/main'
import { PlaceDetails, Places } from 'pages/places'
import { CreateMeetingPage } from 'pages/create-meeting'
import { CreateMeetingSuccessPage } from 'pages/create-meeting-success/create-meeting-success.tsx'
import { CreatePlacePage } from 'pages/create-place'
import { MeetingFiltersPage } from 'pages/meeting-filters'
import { InvitationsListPage } from 'pages/invitations-list'
import { InvitationsFiltersPage } from 'pages/invitations-filters'
import { PersonalPage } from 'pages/personal'
import { MeetingEvolutionSuccessPage } from 'pages/meeting-evaluation-success'
import { MeetingFeedbackPage } from 'pages/meeting-feedback/index.ts'
import { MeetingFeedbackSuccessPage } from 'pages/meeting-feedback-success'
import { GlobalFeedbackPage } from 'pages/global-feedback'
import { ChangeCityPage } from 'pages/change-city'
import { MeetingsHistoryPage } from 'pages/meetings-history'
import { UserLevelSPage } from 'pages/user-level'
import { News } from 'pages/news'
import { NotFoundPage } from 'pages/not-found'
import { IntroRegisterSmsPage } from 'pages/intro/intro-register'
import { RegisterPhonePage } from 'pages/intro/intro-register/phone'

export const router = createBrowserRouter([
  {
    path: SanekRoute.ROOT,
    element: <Root />,
    errorElement: <NotFoundPage />,
  },
  {
    path: SanekRoute.MAIN,
    element: <MainPage />,
  },
  {
    path: SanekRoute.CREATE_MEETING,
    element: <CreateMeetingPage />,
  },
  {
    path: SanekRoute.CREATE_MEETING_SUCCESS,
    element: <CreateMeetingSuccessPage />,
  },
  {
    path: SanekRoute.ACCEPT_INVITATION_SUCCESS,
    element: <AcceptInvitationSuccess />,
  },
  {
    path: `${SanekRoute.MEETING_DETAILS}/:meetingId`,
    element: <MeetingDetails />,
  },
  {
    path: `${SanekRoute.MEETING_CHAT}/:meetingId`,
    element: <ChatMessagesPage />,
  },
  {
    path: `${SanekRoute.CREATE_CHAT_MESSAGE}/:meetingId/:chatId?`,
    element: <CreateMessagePage />,
  },
  {
    path: SanekRoute.CREATE_PLACE,
    element: <CreatePlacePage />,
  },
  {
    path: SanekRoute.PLACES,
    element: <Places />,
  },
  {
    path: `${SanekRoute.PLACE_DETAILS}/:placeId`,
    element: <PlaceDetails />,
  },
  {
    path: SanekRoute.INTRO_REGISTER,
    element: <IntroRegisterPage />,
  },
  {
    path: SanekRoute.INTRO_REGISTER_SMS,
    element: <IntroRegisterSmsPage />,
  },
  {
    path: SanekRoute.INTRO_REGISTER_SMS_PHONE,
    element: <RegisterPhonePage />,
  },
  {
    path: SanekRoute.INTRO_LETS_TALK,
    element: <IntroLetsTalkPage />,
  },
  {
    path: SanekRoute.INTRO_SANEK_GREETING,
    element: <IntroSanekGreetingPage />,
  },
  {
    path: SanekRoute.SURVEY_QUESTIONS,
    element: <SurveyQuestions />,
  },
  {
    path: SanekRoute.SURVEY_CUSTOMIZATION,
    element: <SurveyCustomizationPage />,
  },
  {
    path: SanekRoute.SURVEY_ROLES,
    element: <SurveyRoles />,
  },
  {
    path: SanekRoute.SURVEY_COMMON,
    element: <SurveyCommon />,
  },
  {
    path: SanekRoute.SURVEY_AVATARS,
    element: <SurveyAvatars />,
  },
  {
    path: `${SanekRoute.MEETING_FILTERS}`,
    element: <MeetingFiltersPage />,
  },
  {
    path: `${SanekRoute.INVITATIONS_LIST}`,
    element: <InvitationsListPage />,
  },
  {
    path: `${SanekRoute.INVITATIONS_FILTERS}`,
    element: <InvitationsFiltersPage />,
  },
  {
    path: `${SanekRoute.PERSONAL}`,
    element: <PersonalPage />,
  },
  {
    path: `${SanekRoute.PERSONAL_LEVELS}`,
    element: <UserLevelSPage />,
  },
  {
    path: `${SanekRoute.PERSONAL_CHANGE_CITY}`,
    element: <ChangeCityPage />,
  },
  {
    path: `${SanekRoute.MEETING_EVOLUTION_SUCCESS}/:meetingId`,
    element: <MeetingEvolutionSuccessPage />,
  },
  {
    path: `${SanekRoute.MEETING_FEEDBACK}/:meetingId`,
    element: <MeetingFeedbackPage />,
  },
  {
    path: `${SanekRoute.MEETING_FEEDBACK_SUCCESS}`,
    element: <MeetingFeedbackSuccessPage />,
  },
  {
    path: `${SanekRoute.PERSONAL_MEETINGS_HISTORY}`,
    element: <MeetingsHistoryPage />,
  },
  {
    path: `${SanekRoute.GLOBAL_FEEDBACK}`,
    element: <GlobalFeedbackPage />,
  },
  {
    path: `${SanekRoute.NEWS}/:id`,
    element: <News />,
  },
  {
    path: `${SanekRoute.SUBSCRIPTIONS}`,
    element: <Subscriptions />,
  },
  {
    path: `${SanekRoute.SUBSCRIPTION_PROCEED}`,
    element: <SubscriptionProceed />,
  },
  {
    path: `${SanekRoute.SUBSCRIPTION_BUY_SUCCESS}`,
    element: <SubscriptionBuySuccess />,
  },
  {
    path: `${SanekRoute.SUBSCRIPTION_BUY_ERROR}`,
    element: <SubscriptionBuyError />,
  },
])
