import { css } from '@emotion/css'

export const pageContainer = css`
  padding: 24px;

  h1 {
    text-align: center;
  }
`

export const description = css`
  margin-top: 55px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
`

export const inputWrapper = css`
  margin-top: 12px;
`

export const action = css`
  text-align: center;
  text-decoration: underline;
  margin-top: 4px;
`

export const sendPhoneButton = css`
  margin-top: 20px;
`
export const smsCodeForm = css`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`

export const hint = css`
  text-align: center;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 8px;
`

export const loginButton = css`
  margin-top: 12px;
`
export const errorText = css`
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  color: #c80000;
`
