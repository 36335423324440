import { userMeetingsTransport } from 'api/user-meetings/user-meetings.transport.ts'
import Plus from 'assets/plus.svg'
import { CenteredLoader } from 'components/common/centered-loader'
import { LayoutPage } from 'components/layout-page'
import { MeetingTimePicker } from 'components/meeting-time-picker'
import { SelectedTimeslots } from 'components/selected-timeslots'
import { observer } from 'mobx-react-lite'
import { Fragment, JSX, useState } from 'react'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { meetingCategoriesStore } from 'store/meeting-categories.store.ts'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'
import { meetingMoodsStore } from 'store/meeting-moods.store.ts'
import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { SanekButton } from 'ui/sanek-button'
import { navigateBack } from 'utils/navigation.ts'
import { CategoriesBlock } from './categories-block'
import * as styles from './create-meeting.style.ts'
import { FavouritesButton } from './favourites-button'
import { MoodsBlock } from './moods-block'
import { SelectedPlace } from './selected-place'
import { SeparatorGrayThin } from 'components/common/separator/separator-gray-thin.tsx'

export const CreateMeetingPage = observer((): JSX.Element => {
  const { isLoading: isMoodsLoading } = meetingMoodsStore
  const { isLoading: isCategoriesLoading } = meetingCategoriesStore

  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { user } = userStore
  const { selectedPlace, selectedTimeslots, selectedMood, selectedCategory } =
    meetingCreationStore

  const canCreateMeeting = Boolean(
    user &&
      selectedPlace &&
      selectedMood &&
      selectedCategory &&
      selectedTimeslots.length > 0
  )

  if (isMoodsLoading || isCategoriesLoading) {
    return <CenteredLoader />
  }

  const handleFavouritesClick = (): void => {
    void router.navigate(SanekRoute.PLACES, {
      state: { onlyFavourites: true },
    })
  }

  const handleAddPlace = (): void => {
    void router.navigate(SanekRoute.CREATE_PLACE)
  }

  const handleCreate = (): void => {
    if (!canCreateMeeting) {
      return
    }
    setIsProcessing(true)

    void userMeetingsTransport
      .create({
        selectedPlace: selectedPlace!,
        selectedMoodId: selectedMood!.id,
        selectedCategoryId: selectedCategory!.id,
        userId: user!.id,
        timeslots: selectedTimeslots,
      })
      .then(() => {
        void router.navigate(SanekRoute.CREATE_MEETING_SUCCESS)
        meetingCreationStore.reset()
      })
      .finally(() => setIsProcessing(false))
  }

  const handleNavigateBack = (): void => {
    meetingCreationStore.reset()
    navigateBack()
  }

  return (
    <LayoutPage headerText="Выбрать место" backAction={handleNavigateBack}>
      <div className={styles.createMeetingPage}>
        <p className={styles.title}>Какое настроение задать встрече?</p>
        <CellEmpty height="10" />

        <MoodsBlock />
        <CellEmpty height="24" />

        <FlexContainer justify="space-between" align="center" wrap="nowrap">
          <p className={styles.title}>Куда пойдем?</p>
          <FavouritesButton onClick={handleFavouritesClick}>
            Избранное
          </FavouritesButton>
        </FlexContainer>
        <CellEmpty height="10" />

        <CategoriesBlock />
        <CellEmpty height="20" />

        <SeparatorGrayThin />

        {!selectedPlace && (
          <Fragment>
            <div className={styles.offset}>
              <p className={styles.question}>Не нашел подходящее заведение?</p>
            </div>
            <CellEmpty height="8" />

            <div className={styles.addPlaceButtonWrapper}>
              <button onClick={handleAddPlace}>
                <img src={Plus} />
                <p>Добавить свое место</p>
              </button>
            </div>
            <CellEmpty height="48" />
          </Fragment>
        )}

        {selectedPlace && (
          <Fragment>
            <div className={styles.offset}>
              <SelectedPlace />
            </div>
            <CellEmpty height="24" />
          </Fragment>
        )}

        <SeparatorGrayThin />
        <CellEmpty height="12" />

        <p className={styles.title}>Когда можешь пойти?</p>
        <CellEmpty height="8" />

        <MeetingTimePicker
          selectedTimeslots={selectedTimeslots}
          onAddTimeslot={meetingCreationStore.addSelectedTimeslot}
        />
        <CellEmpty height="14" />

        <p className={styles.editTimeDescription}>
          Выбери временные интервалы, когда у&nbsp;тебя есть свободное время
        </p>

        <CellEmpty height="28" />

        <SelectedTimeslots
          timeslots={selectedTimeslots}
          onRemoveItem={meetingCreationStore.removeSelectedTimeslot}
        />
        <CellEmpty height="38" />

        <SanekButton
          disabled={!canCreateMeeting}
          onClick={handleCreate}
          isLoading={isProcessing}
        >
          НАПРЯЧЬ САНЬКА
        </SanekButton>
      </div>
    </LayoutPage>
  )
})
