import { CityModel } from 'api/cities/models/city.model.ts'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import ChevronsDown from 'assets/chevrons-down.svg'
import ManTall from 'assets/man_tall.png'
import Monolog from 'assets/monolog2.svg'
import { DateOfBirthPicker } from 'components/date-of-birth-picker'
import { SpeechBubble } from 'components/speech-bubble'
import { CityPicker } from 'components/city-picker'
import { USER_NOT_FOUND_ERROR } from 'const/common.const.ts'
import { STRAPI_DATE_FORMAT } from 'const/date.const.ts'
import { SurveyStep, UserGender } from 'enums/user.enum.ts'
import { GENDER_OPTIONS } from 'pages/survey/survey-common/survey-common.const.ts'
import stylesCommon from 'pages/survey/survey.module.css'
import { ChangeEvent, JSX, useState } from 'react'

import { userStore } from 'store/user.store.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { CenteredInput } from 'ui/centered-input'
import { ProgressBar } from 'ui/progress-bar'
import { Radio, TRadioOption } from 'ui/radio'
import { SanekButton } from 'ui/sanek-button'
import * as styles from './survey-common.style.ts'
import dayjs, { Dayjs } from 'dayjs'
import { useRollbar } from '@rollbar/react'
import { CreateUserLoadingScreen } from 'components/common/create-user-loading-screen'
import { usersTransport } from 'api/users.transport.ts'
import { SanekUser } from 'api/models/sanek-user.model.ts'

const ANIMATION_TIME = 6000

export function SurveyCommon(): JSX.Element {
  const { user, setUser } = userStore

  const [sanekUser, setSanekUser] = useState<SanekUser | null>(null)

  const [isShowLoader, setIsShowLoader] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [name, setName] = useState(user?.name || '')
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(
    user?.dateOfBirth && dayjs(user.dateOfBirth).isValid()
      ? dayjs(user.dateOfBirth)
      : dayjs('1995-01-01')
  )

  const [city, setCity] = useState<CityModel | null>(user?.city || null)
  const [gender, setGender] = useState<UserGender | null>(user?.gender || null)

  const rollbar = useRollbar()

  const isFormValid = !!name && !!dateOfBirth && !!gender && !!city

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const handleDateChange = (date: Dayjs | null): void => {
    setDateOfBirth(date)
  }

  const handleCityChange = (value: CityModel): void => {
    setCity(value)
  }

  const handleGenderChange = (value: UserGender): void => {
    setGender(value)
  }

  const handleContinue = (): void => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    if (!name || !dateOfBirth || !gender || !city) {
      rollbar.error('Поля формы не заполнены')
      return
    }

    setIsSaving(true)

    sanekUsersTransport
      .saveUserCommonData(user.id, {
        name,
        dateOfBirth: dateOfBirth.format(STRAPI_DATE_FORMAT),
        cityId: city.id,
        gender,
      })
      .then(async () => {
        setIsSaving(false)
        usersTransport.getMe().then((currentUser) => {
          setSanekUser(currentUser)
          setIsShowLoader(true)

          sanekUsersTransport
            .setSurveyStep(SurveyStep.CUSTOMIZATION, user.id)
            .then(() => {
              setTimeout(() => {
                setUser({
                  ...currentUser,
                  surveyStep: SurveyStep.CUSTOMIZATION,
                })
              }, ANIMATION_TIME)
            })
        })
      })
      .catch((error) => {
        setIsSaving(false)
        rollbar.error('Ошибка при сохранении Sanek User:', error)
      })
  }

  if (isShowLoader && sanekUser) {
    return <CreateUserLoadingScreen user={sanekUser} />
  }

  return (
    <div className={styles.surveyCommonPage}>
      <div className={stylesCommon.surveyHeader}>
        <h3 className={stylesCommon.surveyHeaderTitle}>
          Дружба с&nbsp;Саньком
        </h3>
        <CellEmpty height="7" />

        <ProgressBar percentage={95} />
        <CellEmpty height="20" />

        <h3 className={stylesCommon.surveyHeaderQuestion}>
          Еще немного о&nbsp;тебе:
        </h3>
      </div>

      <div className={styles.surveyCommonPageContent}>
        <div>
          <CenteredInput
            isBig
            placeholder="Введите имя"
            value={name}
            onChange={handleNameChange}
          />
          <CellEmpty height="18" />

          <DateOfBirthPicker value={dateOfBirth} onChange={handleDateChange} />
          <CellEmpty height="18" />

          <CityPicker onChange={handleCityChange} value={city?.name} />
          <CellEmpty height="20" />

          <div className={styles.genderOptionsWrapper}>
            {GENDER_OPTIONS.map((genderOption: TRadioOption<UserGender>) => (
              <Radio
                key={genderOption.value}
                value={genderOption.value}
                checked={gender === genderOption.value}
                isBig
                onChange={handleGenderChange}
              >
                {genderOption.label}
              </Radio>
            ))}
          </div>
          <CellEmpty height="30" />
        </div>

        <div className={styles.footerContainer}>
          <img src={ManTall} alt="Санёк" className={styles.sanekImage} />

          <div className={styles.speechBubble}>
            <SpeechBubble
              backgroundImage={Monolog}
              topOffsetText={2}
              leftOffsetText={35}
            >
              <p className={styles.speechBubbleContent}>
                Остался последний шаг!
              </p>
            </SpeechBubble>
          </div>

          <img src={ChevronsDown} className={styles.chevronsImage} />

          <SanekButton
            disabled={!isFormValid}
            isLoading={isSaving}
            onClick={handleContinue}
          >
            Далее
          </SanekButton>
          <CellEmpty height="10" />

          <p className={styles.policy}>
            Нажимая на кнопку “Далее”, вы соглашаетесь с{' '}
            <a href={import.meta.env.VITE_POLICY_URL} target="_blank">
              Политикой конфенденциальности
            </a>{' '}
            и{' '}
            <a href={import.meta.env.VITE_TERMS_URL} target="_blank">
              Пользовательским соглашением
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
