import { css } from '@emotion/css'

export const createMeetingPage = css`
  padding: 0 1.5rem 2rem;
`
export const addPlaceButtonWrapper = css`
  display: flex;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 17px;
    border: 3px solid #f2f2f2;
    background: #f6f6f6;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
  }

  p {
    color: #000;
    font-family: Copperplate, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 8px;
  }
`

export const offset = css`
  padding: 0 0.5rem;
`

export const question = css`
  color: #000;
  font-family: 'Chalkboard SE', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const title = css`
  color: #000;
  font-family: 'Chalkboard SE', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const editTimeDescription = css`
  font-weight: 300;
  font-size: 10px;
`
