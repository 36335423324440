import { userStore } from 'store/user.store.ts'
import * as styles from './personal.style.ts'
import { cx } from '@emotion/css'
import * as commonStyles from 'styles/common.style.ts'
import { LayoutPage } from 'components/layout-page/layout-page.tsx'
import { navigateBack } from 'utils/navigation.ts'
import { observer } from 'mobx-react-lite'
import { SanekButton } from 'ui/sanek-button/sanek-button.tsx'
import { SVGImg } from 'ui/svg-img/svg-img.tsx'
import Crown from 'assets/crown.svg'
import Vortex from 'assets/personal-page-vortex.svg'
import Stroke from 'assets/personal-page-stroke.svg'
import Hanger from 'assets/hanger.png'
import List from 'assets/list.svg'
import Pen from 'assets/pen.svg'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums'
import { closeAllDialogs, openDialog } from 'utils/dialog.ts'
import { ConfirmRepeatSurveyModal } from 'components/confirm-repeat-survey-modal'
import { ConfirmDeleteAccountModal } from 'components/confirm-delete-account-modal'
import { sanekUsersTransport } from 'api/sanek-users.transport.ts'
import { USER_NOT_FOUND_ERROR } from 'const/common.const.ts'
import { CellEmpty } from '../../ui/cell-empty.tsx'
import { useRollbar } from '@rollbar/react'
import { SubscriptionState } from 'enums/subscription.enums.ts'
import { UserGender } from 'enums/user.enum.ts'
import { SeparatorGray } from 'components/common/separator'
import { CenteredLoader } from 'components/common/centered-loader'
import { ProgressiveImage } from 'components/progressive-image/progressive-image.tsx'

export const PersonalPage = observer((): JSX.Element => {
  const { user, isLoading } = userStore

  const rollbar = useRollbar()

  const handleGetFeedback = () => {
    void router.navigate(SanekRoute.GLOBAL_FEEDBACK)
  }

  const handleChangeCity = () => {
    void router.navigate(SanekRoute.PERSONAL_CHANGE_CITY)
  }

  const handleOpenAvatars = (): void => {
    void router.navigate(SanekRoute.SURVEY_AVATARS)
  }

  const handleGoMeetingsHistory = (): void => {
    void router.navigate(SanekRoute.PERSONAL_MEETINGS_HISTORY)
  }

  const handleGoUserLevelsPage = (): void => {
    void router.navigate(SanekRoute.PERSONAL_LEVELS)
  }

  const handleGoSubscriptionsPage = (): void => {
    void router.navigate(SanekRoute.SUBSCRIPTIONS)
  }

  const deleteAccount = (): void => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    sanekUsersTransport
      .deleteUser(user.id)
      .then(() => {
        userStore.logout()
        closeAllDialogs()
        void router.navigate('/')
      })
      .catch((error) => {
        rollbar.error('Ошибка при попытке удаления аккаунта:', error)
      })
  }

  const handleOpenDeleteAccountConfirm = () => {
    openDialog({
      content: (
        <ConfirmDeleteAccountModal
          onClose={closeAllDialogs}
          onAccept={deleteAccount}
        />
      ),
    })
  }

  const removeSurveyData = () => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    sanekUsersTransport
      .setUserRepeatSurvey(user.id)
      .then(() => {
        userStore.getMe().then(() => {
          closeAllDialogs()
          void router.navigate('/')
        })
      })
      .catch((error) => {
        rollbar.error(
          'Ошибка при попытке повторного прохождения опроса:',
          error
        )
      })
  }

  const handleOpenRepeatSurveyConfirm = (): void => {
    openDialog({
      content: (
        <ConfirmRepeatSurveyModal
          onClose={closeAllDialogs}
          onAccept={removeSurveyData}
        />
      ),
    })
  }

  const getSubscriptionDisplayText = () => {
    if (
      !user?.subscription ||
      user.subscription.state === SubscriptionState.EXPIRED
    ) {
      return 'Купить подписку'
    }

    if (user.subscription.state === SubscriptionState.EXPIRE_SOON) {
      return `${user.subscription.remainingDays} дней`
    }

    return 'Есть подписка'
  }

  if (isLoading) {
    return <CenteredLoader />
  }

  if (!user) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage headerText="Твой профиль" backAction={navigateBack}>
      <div className={styles.headerBlock}>
        <div
          className={styles.subscriptionBtn}
          onClick={handleGoSubscriptionsPage}
        >
          <SVGImg src={Crown} />
          {getSubscriptionDisplayText()}
        </div>
        <div className={styles.locationBtn} onClick={handleChangeCity}>
          {user?.city?.name}
        </div>
      </div>

      <div className={styles.vortexContainer}>
        <SVGImg src={Vortex} className={styles.vortex} />
      </div>

      <div className={styles.userLevelBlock} onClick={handleGoUserLevelsPage}>
        <span>
          Уровень
          <SVGImg src={Stroke} className={styles.stroke} />
        </span>
        <div className={styles.userLevel}>
          {user?.gender === UserGender.MALE
            ? user?.level?.maleTitle
            : user?.level?.femaleTitle}
        </div>
      </div>

      <div className={styles.avatarsBlock}>
        <ProgressiveImage
          image={user.avatar.image}
          alt="Аватар"
          className={styles.userAvatar}
        />
      </div>

      <div className={styles.avatarsVortexBlock}>
        <SeparatorGray className={styles.separator} />
      </div>

      <CellEmpty height="15" />

      <div className={styles.buttonsBlock}>
        <div onClick={handleOpenAvatars}>
          <div className={styles.topBtn}>
            <img src={Hanger} alt="Вешалка" width="40" height="36" />
          </div>
          <span className={styles.buttonText}>
            Cменить <br />
            аватар
          </span>
        </div>

        <div>
          <div className={styles.topBtn}>100%</div>
          <span className={styles.buttonText}>
            Дружба с<br />
            Саньком
          </span>
        </div>
      </div>

      <CellEmpty height="25" />

      <div className={styles.buttonsBlock}>
        <div onClick={handleGoMeetingsHistory}>
          <div className={styles.topBtn}>
            <img src={List} alt="История" />
          </div>
          <span className={styles.buttonText}>
            История
            <br />
            встреч
          </span>
        </div>

        <div>
          <div
            className={styles.topBtn}
            onClick={handleOpenRepeatSurveyConfirm}
          >
            <img src={Pen} alt="Опрос" />
          </div>
          <span className={styles.buttonText}>
            Пройти
            <br />
            опрос
          </span>
        </div>
      </div>

      <div className={styles.vortexContainer}>
        <SeparatorGray className={styles.separator} />
      </div>

      <div className={styles.bottomBlock}>
        <SanekButton
          type="secondary"
          onClick={handleGetFeedback}
          className={cx(styles.sanekBtn, styles.whiteText)}
        >
          Дать Саньку обратную связь
        </SanekButton>

        <div>
          <SanekButton
            type="default"
            size="s"
            onClick={handleOpenDeleteAccountConfirm}
            className={styles.sanekBtn}
          >
            Удалить аккаунт
          </SanekButton>
          <CellEmpty height="20" />
          <div className={commonStyles.policy}>
            <a href={import.meta.env.VITE_POLICY_URL} target="_blank">
              Политика конфенденциальности
            </a>
            <CellEmpty height="5" />

            <a href={import.meta.env.VITE_TERMS_URL} target="_blank">
              Пользовательское соглашение
            </a>
            <CellEmpty height="10" />

            <p>
              Владелец сайта ИП Нестеров Иван Александрович (ИНН 771817136188,
              ОГРНИП 321774600045852) Чтобы связаться, пишите на почту
              vanesterovolympiad@yandex.ru
            </p>
          </div>
        </div>
      </div>
    </LayoutPage>
  )
})
