import axios, { AxiosResponse } from 'axios'
import {
  TTelegramAuthResponse,
  TTelegramRegistrationUser,
} from './models/telegram.model.ts'

export class TelegramTransport {
  auth(user: TTelegramRegistrationUser): Promise<TTelegramAuthResponse> {
    return axios
      .post(`${import.meta.env.VITE_STRAPI_API}/api/auth/local`, user)
      .then(({ data }: AxiosResponse<TTelegramAuthResponse>) => {
        const jwt = data.jwt
        if (jwt) {
          localStorage.setItem('jwt', jwt)
        }

        return data
      })
  }
}

export const telegramTransport = new TelegramTransport()
