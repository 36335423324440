import {
  TTelegramUser,
  TTelegramRegistrationUser,
} from 'api/models/telegram.model.ts'

export const convertTelegramUserToRegisterData = (
  user: TTelegramUser
): TTelegramRegistrationUser => {
  return {
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    username: user.username,
    photoUrl: user.photo_url,
    authDate: user.auth_date,
    hash: user.hash,
  }
}
