import { meetingMoodsStore } from 'store/meeting-moods.store.ts'
import { meetingCategoriesStore } from 'store/meeting-categories.store.ts'
import { storiesStore } from 'store/stories.store.ts'
import { userLevelsStore } from 'store/user-levels.store'
import { userStore } from 'store/user.store.ts'
import { userMeetingsStore } from 'store/user-meetings.store.ts'
import { userInvitationsStore } from 'store/invitations.store.ts'
import { archiveMeetingsStore } from 'store/archive-meetings.store.ts'

export class AppService {
  static initStoreData() {
    void meetingMoodsStore.getMoods()
    void meetingCategoriesStore.getCategories()
    void storiesStore.getStories()
    void userLevelsStore.getLevels()
  }

  static initUserData(): void {
    void userStore.getMe()
    void archiveMeetingsStore.getArchiveMeetings()
    void userMeetingsStore.getMeetings()
    void userInvitationsStore.getInvitations()
  }
}
