import {
  TSanekUserChangeCityData,
  TSanekUserCommonData,
} from 'api/models/sanek-user.model.ts'
import { AxiosResponse } from 'axios'
import { SurveyStep } from '../enums/user.enum.ts'
import { transport } from './transport.ts'

export class SanekUsersTransport {
  setUserPassedOnboarding(sanekUserId: number) {
    return transport
      .put(
        `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
        {
          data: {
            has_passed_onboarding: true,
          },
        }
      )
      .then(({ data }: AxiosResponse) => data.data)
  }

  setSurveyStep(step: SurveyStep, sanekUserId: number) {
    return transport.put(
      `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
      {
        data: {
          survey_step: step,
        },
      }
    )
  }

  setUserRepeatSurvey(sanekUserId: number) {
    return transport
      .put(
        `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
        {
          data: {
            has_passed_survey: false,
            survey_step: SurveyStep.QUESTIONS,
          },
        }
      )
      .then(({ data }: AxiosResponse) => data.data)
  }

  setAvatar(sanekUserId: number, avatarId: number) {
    return transport
      .put(
        `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
        {
          data: {
            avatar: {
              connect: [avatarId],
            },
          },
        }
      )
      .then(({ data }: AxiosResponse) => data.data)
  }

  setLastSeen(sanekUserId: number) {
    return transport
      .put(
        `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
        {
          data: {
            last_visit: new Date(),
          },
        }
      )
      .then(({ data }: AxiosResponse) => data.data)
  }

  saveUserCommonData(sanekUserId: number, data: TSanekUserCommonData) {
    return transport
      .put(
        `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
        {
          data: {
            name: data.name,
            date_of_birth: data.dateOfBirth,
            city: {
              connect: [data.cityId],
            },
            gender: data.gender,
          },
        }
      )
      .then(({ data }: AxiosResponse) => data.data)
  }

  changeUserCityData(sanekUserId: number, data: TSanekUserChangeCityData) {
    return transport
      .put(
        `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
        {
          data: {
            city: {
              connect: [data.cityId],
            },
          },
        }
      )
      .then(({ data }: AxiosResponse) => data.data)
  }

  finishSurvey(sanekUserId: number, voice: HTMLAudioElement) {
    return fetch(voice.src)
      .then((response) => response.blob())
      .then((audioBlob: Blob) => {
        const voice = new File([audioBlob], 'audio', { type: 'audio/mp3' })

        const formData = new FormData()
        formData.append('files', voice)

        return transport
          .post(`${import.meta.env.VITE_STRAPI_API}/api/upload`, formData)
          .then(({ data }: AxiosResponse) => {
            return transport.put(
              `${
                import.meta.env.VITE_STRAPI_API
              }/api/sanek-users/${sanekUserId}`,
              {
                data: {
                  has_passed_survey: true,
                  voice: data[0],
                },
              }
            )
          })
          .then(({ data }: AxiosResponse) => data.data)
      })
  }

  addPlaceToFavourites(sanekUserId: number, placeId: number): Promise<void> {
    return transport.put(
      `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
      {
        data: {
          favourite_places: {
            connect: [placeId],
          },
        },
      }
    )
  }

  removePlaceFromFavourites(
    sanekUserId: number,
    placeId: number
  ): Promise<void> {
    return transport.put(
      `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`,
      {
        data: {
          favourite_places: {
            disconnect: [placeId],
          },
        },
      }
    )
  }

  deleteUser(sanekUserId: number) {
    return transport.delete(
      `${import.meta.env.VITE_STRAPI_API}/api/sanek-users/${sanekUserId}`
    )
  }
}

export const sanekUsersTransport = new SanekUsersTransport()
