import { makeAutoObservable } from 'mobx'
import { MeetingMoodModel } from 'api/meeting/models/meeting-mood.model.ts'
import { MeetingCategoryModel } from 'api/meeting/models/meeting-category.model.ts'
import { PlaceModel } from 'api/places/models/place.model.ts'
import { TPickerTimeslot } from 'types/time-picker.types.ts'

class MeetingMoodsStore {
  selectedMood: MeetingMoodModel | null = null
  selectedCategory: MeetingCategoryModel | null = null
  selectedTimeslots: Array<TPickerTimeslot> = []
  selectedPlace: PlaceModel | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedMood = (mood: MeetingMoodModel): void => {
    this.selectedMood = mood
  }

  setSelectedCategory = (category: MeetingCategoryModel | null): void => {
    this.selectedCategory = category
  }

  setSelectedPlace = (selectedPlace: PlaceModel | null): void => {
    this.selectedPlace = selectedPlace
  }

  addSelectedTimeslot = (timeslot: TPickerTimeslot): void => {
    this.selectedTimeslots = [...this.selectedTimeslots, timeslot]
  }

  removeSelectedTimeslot = (removedTimeslot: TPickerTimeslot): void => {
    const newSelectedMeetingTimes = this.selectedTimeslots.filter(
      (timeslot: TPickerTimeslot) => timeslot.id !== removedTimeslot.id
    )
    this.selectedTimeslots = [...newSelectedMeetingTimes]
  }

  reset = (): void => {
    this.selectedMood = null
    this.selectedCategory = null
    this.selectedPlace = null
    this.selectedTimeslots = []
  }
}

export const meetingCreationStore = new MeetingMoodsStore()
