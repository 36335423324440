import * as styles from './meeting-evaluation-success.style'
import { JSX } from 'react'
import { router } from 'router'
import { CellEmpty } from 'ui/cell-empty'
import { SanekButton } from 'ui/sanek-button'
import { SanekRoute } from 'router/router.enums'
import { useScrollToTop } from 'hooks/useScrollToTop.ts'
import starImg from 'assets/star.png'
import SpraySvg from 'assets/spray-success-page.svg'
import ManTall from 'assets/man_tall.png'
import SanekButtonVortex from 'assets/sanek-button-vortex.svg'
import { useParams } from 'react-router-dom'

export const MeetingEvolutionSuccessPage = (): JSX.Element => {
  const { meetingId } = useParams()

  useScrollToTop()

  const handleGetFeedback = (): void => {
    void router.navigate(`${SanekRoute.MEETING_FEEDBACK}/${meetingId}`)
  }

  const handleNavigateToMainPage = (): void => {
    void router.navigate(SanekRoute.MAIN)
  }

  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.header}>ОЦЕНКА ВСТРЕЧИ</h2>
      <div className={styles.starsBlock}>
        <img src={starImg} alt="star" width="39" height="39" />
        Спасибо
        <img src={starImg} alt="star" width="39" height="39" />
      </div>
      <span className={styles.title}>за оценку встречи!</span>
      <CellEmpty height={35} />
      <img
        src={ManTall}
        alt="Санёк"
        width="187"
        height="273"
        className={styles.sanekPng}
      />
      <img src={SpraySvg} height="534" className={styles.spraySvg} />
      <div className={styles.buttonGetFeedback}>
        <SanekButton
          onClick={handleGetFeedback}
          type="secondary"
          className={styles.buttonGetFeedbackStyles}
        >
          ДАТЬ САНЬКУ ОБРАТНУЮ СВЯЗЬ
        </SanekButton>
      </div>
      <div className={styles.buttonBlock}>
        <img src={SanekButtonVortex} className={styles.sanekButtonVortex} />
        <SanekButton onClick={handleNavigateToMainPage}>НА ГЛАВНУЮ</SanekButton>
      </div>
    </div>
  )
}
