export const initGoogleAnalytics = () => {
  if (import.meta.env.VITE_APP_ENV !== 'production') {
    return
  }

  const GOOGLE_ID = 'G-JQ6CPD8B34'

  let script = document.createElement('script')
  script.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ID}`
  )
  script.setAttribute('async', '')
  document.head.appendChild(script)

  script = document.createElement('script')
  script.type = 'text/javascript'
  script.text = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${GOOGLE_ID}');
`
  document.head.appendChild(script)
}
