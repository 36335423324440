import { JSX, useEffect, useState } from 'react'
import styles from './survey-roles.module.css'
import { CellEmpty } from 'ui/cell-empty'
import { ProgressBar } from 'ui/progress-bar'
import { TRoleModel } from 'api/models/role.model'
import { rolesTransport } from 'api/all-roles.transport'
import { SurveyRoleTip } from './survey-role-tip'
import { SanekButton } from 'ui/sanek-button'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { userStore } from 'store/user.store.ts'
import { USER_NOT_FOUND_ERROR } from 'const/common.const.ts'
import { CenteredLoader } from 'components/common/centered-loader'
import { useRollbar } from '@rollbar/react'

export function SurveyRoles(): JSX.Element {
  const { user } = userStore

  const [roles, setRoles] = useState<Array<TRoleModel>>([])
  const [selectedRoles, setSelectedRoles] = useState<Set<number>>(new Set())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const rollbar = useRollbar()

  useEffect(() => {
    setIsLoading(true)
    void rolesTransport
      .getRoles()
      .then(setRoles)
      .finally(() => setIsLoading(false))
  }, [])

  const handleRoleClick = (roleId: number) => {
    setSelectedRoles((prevSelected) => {
      const newSelected = new Set(prevSelected)
      if (newSelected.has(roleId)) {
        newSelected.delete(roleId)
      } else {
        newSelected.add(roleId)
      }
      return newSelected
    })
  }

  const handleSave = (): void => {
    if (!user) {
      rollbar.error(USER_NOT_FOUND_ERROR)
      return
    }

    setIsProcessing(true)

    const result = roles.map((role) => ({
      role_question: role.id,
      value: selectedRoles.has(role.id),
    }))

    rolesTransport
      .createRoleSurvey(user, result)
      .then(() => {
        void router.navigate(SanekRoute.SURVEY_COMMON, {
          replace: true,
        })
      })
      .catch((error) => {
        rollbar.error('Ошибка при создании Role Survey:', error)
      })
      .finally(() => setIsProcessing(false))
  }

  if (isLoading) {
    return <CenteredLoader />
  }

  const hasSelectedRoles = selectedRoles.size === 0

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerBlock}>
        <h3 className={styles.headerBlockTitle}>Дружба с&nbsp;Саньком</h3>
        <CellEmpty height="7" />

        <ProgressBar percentage={77} />
        <CellEmpty height="20" />

        <h3 className={styles.headerBlockQuestion}>В душе я больше:</h3>
        <CellEmpty height="9" />
        <h3 className={styles.headerBlockDesc}>
          Можно выбрать несколько вариантов ответов
        </h3>
      </div>
      <CellEmpty height="20" />
      <div className={styles.cardsContainer}>
        {roles.map((role) => (
          <SurveyRoleTip
            key={role.id}
            title={role.attributes.title}
            image={role.attributes.image?.data?.attributes}
            isSelected={selectedRoles.has(role.id)}
            onClick={() => handleRoleClick(role.id)}
          />
        ))}
      </div>
      <div className={styles.buttonSaveContainer}>
        {hasSelectedRoles && <p className={styles.formAlert}>Выбери роль!</p>}
        <SanekButton
          onClick={handleSave}
          disabled={hasSelectedRoles}
          isLoading={isProcessing}
        >
          СОХРАНИТЬ
        </SanekButton>
      </div>
    </div>
  )
}
