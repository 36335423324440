import { FlowResultPage } from 'ui/flow-result-page'
import { useScrollToTop } from 'hooks/useScrollToTop.ts'
import { JSX } from 'react'

export const CreateMeetingSuccessPage = (): JSX.Element => {
  useScrollToTop()

  return (
    <FlowResultPage
      headerText="Встреча создана"
      text="Внимание!"
      description="За 3 часа до начала встречи подтверди в приложении, что ты придешь"
    />
  )
}
