import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosRequestTransformer
} from 'axios';

/** Добавляем данные об авторизации пользователя, если таковые есть */
let transformRequestFunctions: Array<AxiosRequestTransformer> = [
  (data: unknown, headers?: AxiosRequestHeaders) => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      headers?.set('Authorization', `Bearer ${jwt}`)
    }

    return data
  }
];
if (axios.defaults.transformRequest) {
  transformRequestFunctions = transformRequestFunctions.concat(axios.defaults.transformRequest);
}

/**
 * Сконфигурированный axios транспорт
 */
export const transport: AxiosInstance = axios.create({
  transformRequest: transformRequestFunctions
});
