import * as styles from './meetup-buttons.styles'
import ButtonDecoration from 'assets/decorations/main/main-button-decoration.png'
import Vortex from 'assets/main-page-vortex-buttons.svg'
import Message from 'assets/message.svg'
import Bulb from 'assets/bulb.svg'
import ManTall from 'assets/man_tall.png'
import AfterSanek from 'assets/after-sanek-main-page.svg'
import ThinkSanek from 'assets/think-sanek.svg'
import { router } from 'router'
import { SanekRoute } from 'router/router.enums.ts'
import { SVGImg } from 'ui/svg-img'
import { pluralize } from 'utils/pluralize.ts'
import { Toast } from 'antd-mobile'

type TMeetupButtonsProps = {
  meetingsCount: number
  invitationsCount: number
  hasSubscription: boolean
}

function MeetupButtons({
  meetingsCount,
  invitationsCount,
  hasSubscription,
}: TMeetupButtonsProps) {
  const handleNavigateToCreateMeeting = (): void => {
    if (!hasSubscription) {
      Toast.show({ content: <p>Продлите подписку</p> })
      return
    }

    void router.navigate(SanekRoute.CREATE_MEETING)
  }

  const handleNavigateToMeetingsList = (): void => {
    void router.navigate(SanekRoute.INVITATIONS_LIST)
  }

  return (
    <div className={styles.mainContainer}>
      <div
        className={styles.addMeetUpButton}
        onClick={handleNavigateToCreateMeeting}
      >
        <img
          src={ButtonDecoration}
          alt="подчеркивание"
          className={styles.leftPng}
        />
        <div className={styles.rightSide}>
          <SVGImg src={Message} />
          <div className={styles.btnText}>СОЗДАТЬ ВСТРЕЧУ</div>
        </div>
      </div>

      <div className={styles.meetingsCountContainer}>
        <span className={styles.meetingsCount}>{meetingsCount}</span>
        &nbsp;
        {pluralize(
          ['встреча', 'встречи', 'встреч'],
          meetingsCount
        ).toUpperCase()}{' '}
        {pluralize(
          ['создана', 'создано', 'создано'],
          meetingsCount
        ).toUpperCase()}
      </div>

      <div
        className={styles.showInvitesButton}
        onClick={handleNavigateToMeetingsList}
      >
        <img
          src={ButtonDecoration}
          alt="подчеркивание"
          className={styles.leftPng}
        />
        <div className={styles.rightSide}>
          <SVGImg src={Bulb} />
          <div className={styles.btnText}>ПОСМОТРЕТЬ ПРИГЛАШЕНИЯ</div>
        </div>
      </div>

      <div className={styles.invitationsCountContainer}>
        <span className={styles.invitationsCount}>{invitationsCount}</span>
        {pluralize(
          ['приглашение', 'приглашения', 'приглашений'],
          invitationsCount
        )}
      </div>

      <img src={Vortex} className={styles.buttonVortexImg} />

      <img src={ThinkSanek} className={styles.thinkSanek} />
      <img
        src={ManTall}
        alt="Санёк"
        width="174"
        height="253"
        className={styles.sanekPng}
      />
      <img src={AfterSanek} className={styles.afterSanek} />
    </div>
  )
}

export default MeetupButtons
