import { PlaceModel } from 'api/places/models/place.model.ts'
import Spaghetti from 'assets/__temp__spaghetti.svg'
import LeftDecor from 'assets/decorations/places/left-decor.svg'
import Lines from 'assets/decorations/places/lines.svg'
import RightDecor from 'assets/decorations/places/right-decor.svg'
import Loupe from 'assets/loupe.svg'
import { CenteredLoader } from 'components/common/centered-loader'
import { LayoutPage } from 'components/layout-page/layout-page.tsx'
import { MeetingCategoryLabel } from 'components/meeting-category-label'
import { PlacesTabs } from 'components/places-tabs'
import { SanekEmptyView } from 'components/sanek-empty-view'
import { observer } from 'mobx-react-lite'
import { PlaceFilters } from 'pages/places/place-filters'
import { PlaceItem } from 'pages/places/places/place-item.tsx'
import { PlacesCategoriesPanel } from 'pages/places/places/places-categories-panel.tsx'
import { Fragment, JSX, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { placesStore } from 'store/places.store.ts'
import { TPlaceFilterForm } from 'types/place-filters.types.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { navigateBack } from 'utils/navigation.ts'
import * as styles from './places.styles.ts'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'
import { SearchModal } from 'components/search-modal'
import { MetroStationModel } from 'api/models/metro-station.model.ts'
import { closeAllDialogs, openDialog } from 'utils/dialog.ts'
import { Modal } from 'antd-mobile'
import { ModalShowHandler } from 'antd-mobile/es/components/modal'
import { userStore } from 'store/user.store.ts'
import { SeparatorGrayWide } from '../../../components/common/separator'

export const Places = observer((): JSX.Element => {
  const { state } = useLocation()
  const { places, filters, isLoading } = placesStore
  const { user } = userStore

  const modalHandlerRef = useRef<ModalShowHandler | null>(null)

  const handleCloseModal = (): void => {
    modalHandlerRef.current?.close()
  }

  useEffect(() => {
    return () => {
      modalHandlerRef.current = null
    }
  }, [])

  const openSearchModal = () => {
    modalHandlerRef.current = Modal.show({
      content: <SearchModal places={places} onClose={handleCloseModal} />,
      maskClassName: styles.modalMask,
      bodyStyle: { backgroundColor: 'transparent', borderRadius: 0 },
      onClose: handleCloseModal,
      closeOnMaskClick: true,
    })
  }

  const { selectedCategory, selectedPlace } = meetingCreationStore

  const isFavouritesPage = !!state?.onlyFavourites

  const categoryIcon = isFavouritesPage
    ? undefined
    : selectedCategory?.image.url || Spaghetti
  const categoryText = isFavouritesPage ? 'Избранное' : selectedCategory?.text

  const emptyViewData = useMemo(() => {
    if (isFavouritesPage) {
      return {
        title: 'Санёк не\u00a0может найти избранные заведения',
        description:
          'Добавь место/заведение в\u00a0избранное, и\u00a0оно здесь появится',
      }
    } else {
      return {
        title: 'Санёк не\u00a0может найти заведения по\u00a0данному запросу',
        description: 'Попробуй изменить выбранные фильтры или удалить лишние',
      }
    }
  }, [])

  const { meetingCategoryId, cityId, onlyFavourites } = filters

  useEffect(() => {
    if (isFavouritesPage && !onlyFavourites) {
      placesStore.setFilters({ onlyFavourites: true })
      return
    }

    if (
      meetingCategoryId !== selectedCategory?.id ||
      cityId !== user?.city?.id
    ) {
      placesStore.setFilters({
        meetingCategoryId: selectedCategory?.id,
        cityId: user?.city?.id,
      })
    }

    placesStore.getPlaces()
  }, [isFavouritesPage, meetingCategoryId, cityId, onlyFavourites])

  const handleFiltersFormSubmit = (form: TPlaceFilterForm): void => {
    placesStore.updateFilters({
      cityId: form.city?.id,
      metroStationIds: form.metroStations.map(
        (station: MetroStationModel) => station.id
      ),
      priceSegment: form.priceSegment,
    })
    placesStore.getPlaces()
    closeAllDialogs()
  }

  const handleFiltersOpen = (): void => {
    openDialog({
      content: (
        <PlaceFilters
          backAction={closeAllDialogs}
          onSubmit={handleFiltersFormSubmit}
        />
      ),
    })
  }

  const handleBack = (): void => {
    if (selectedCategory && !selectedPlace) {
      meetingCreationStore.setSelectedCategory(null)
    }

    navigateBack()
  }

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <LayoutPage
      headerText="Выбрать место"
      accessoryRight={
        <div className={styles.searchButton} onClick={openSearchModal}>
          <img src={Loupe} />
        </div>
      }
      backAction={handleBack}
    >
      <div className={styles.placesPage}>
        <img src={LeftDecor} className={styles.leftDecoration} />
        <img src={RightDecor} className={styles.rightDecoration} />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MeetingCategoryLabel iconLeft={categoryIcon}>
            {categoryText}
          </MeetingCategoryLabel>
        </div>
        <CellEmpty height="23" />

        <PlacesCategoriesPanel
          selectedCategory={selectedCategory}
          onlyFavourites={isFavouritesPage}
        />

        <img src={Lines} style={{ width: '100%' }} />
        <CellEmpty height={20} />

        <PlacesTabs onFiltersClick={handleFiltersOpen} />
        <CellEmpty height={25} />

        {places.length > 0 ? (
          places.map((place: PlaceModel) => (
            <Fragment key={place.id}>
              <PlaceItem place={place} />
              <CellEmpty height="20" />
              <SeparatorGrayWide />
              <CellEmpty height="15" />
            </Fragment>
          ))
        ) : (
          <SanekEmptyView {...emptyViewData} />
        )}
      </div>
    </LayoutPage>
  )
})
