import { CityModel } from 'api/cities/models/city.model.ts'
import { TSanekUserAvatar } from 'api/models/telegram.model.ts'
import { SubscriptionModel } from 'api/subscription/models/subscription.model.ts'
import { SurveyStep, UserGender } from 'enums/user.enum.ts'
import { deserialize, JsonName, JsonStruct } from 'tserialize'
import dayjs, { Dayjs } from 'dayjs'
import { UserLevelModel } from './user-level.model'
import { PaymentModel } from '../payment/model/payment.model.ts'

export type TSanekUserCommonData = {
  name: string
  dateOfBirth: string
  cityId: number
  gender: UserGender
}

export type TSanekUserChangeCityData = {
  cityId: number
}

export class SanekUser {
  @JsonName()
  id!: number

  @JsonName('telegram_id')
  telegramId!: string

  @JsonName('name')
  name!: string

  @JsonName('date_of_birth')
  dateOfBirth: Dayjs = dayjs()

  @JsonName('gender')
  gender!: UserGender

  @JsonName('has_passed_onboarding')
  hasPassedOnboarding: boolean = false

  @JsonName('has_passed_survey')
  hasPassedSurvey: boolean = false

  @JsonName('survey_step')
  surveyStep: SurveyStep = SurveyStep.QUESTIONS

  @JsonName()
  avatar!: TSanekUserAvatar

  @JsonName()
  city?: CityModel | undefined

  @JsonStruct(UserLevelModel)
  level?: UserLevelModel

  @JsonStruct(SubscriptionModel)
  subscription: SubscriptionModel | null = null

  @JsonName('created_meetings_count')
  createdMeetingsCount: number = 0

  @JsonStruct(PaymentModel, 'latest_payment')
  latestPayment: PaymentModel | null = null

  static fromServer(data: object): SanekUser {
    return deserialize(data, SanekUser)
  }
}
