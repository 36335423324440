import { css } from '@emotion/css'

const SANEK_HIDE_HEIGHT = 810

export const mainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem 2rem;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
`

export const successSvg = css`
  z-index: 0;
  margin-bottom: 10px;
`

export const info = css`
  z-index: 1;
  font-family:
    Chalkboard SE,
    serif;
  width: 325px;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
`

export const description = css`
  text-align: center;
  font-size: 20px;
  line-height: 20px;
`

export const footer = css`
  width: 100%;
  height: 470px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
`

export const sanekPng = css`
  position: absolute;
  left: calc(50% - 93px);
  bottom: 107px;
  z-index: 1;

  @media (max-height: ${SANEK_HIDE_HEIGHT}px) {
    display: none;
  }
`

export const spraySvg = css`
  position: absolute;
  bottom: -35px;
  width: 100%;

  @media (max-height: ${SANEK_HIDE_HEIGHT}px) {
    display: none;
  }
`

export const buttonBlock = css`
  width: 100%;
  position: absolute;
  bottom: 50px;
  padding: 0 2rem;
  z-index: 2;
`
export const sanekButtonVortex = css`
  position: absolute;
  z-index: 1;
  top: -15px;
  right: 12px;
`
